import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/icons/jb-search-icon/jb-search-icon.scss?inline
var jb_search_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

span {
  cursor: pointer;
  display: var(--display, block);
  height: 25px;
  margin-right: var(--margin-right, 0);
  width: 25px;
}

svg {
  fill: var(--fill-color, black);
  height: 25px;
  transform: rotate(0);
  transition: transform 0.5s ease-in-out 0s;
  width: 25px;
}
span:hover svg {
  transform: rotate(75deg);
}`;

// src/components/icons/jb-search-icon/jb-search-icon.ts
var JbSearchIcon = class extends s {
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("search-icon:clicked", { bubbles: true, composed: true }));
    });
  }
  render() {
    return x`
      <span>
        <svg
          viewbox="0 0 25 25"
          width="25"
          height="25"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            stroke="none"
            stroke-width="1"
            fill-rule="evenodd"
          >
            <g>
              <path
                d="M15.4265331,14.2014302 C15.4835029,14.230695 15.5369674,14.2690073 15.5847592,14.3163669 L24.812371,23.4605259 C25.0625283,23.7084208 25.0625452,24.1103328 24.8124087,24.3582483 L24.4518168,24.8140305 C24.2016509,25.0619752 23.7960129,25.061992 23.5458262,24.8140679 L14.3180638,15.6697596 C14.2527311,15.6050177 14.2044601,15.5297709 14.1732508,15.4495059 C12.6780166,16.6636764 10.7717328,17.3913043 8.6955061,17.3913043 C3.8930191,17.3913043 -2.27373675e-13,13.4982039 -2.27373675e-13,8.69557913 C-2.27373675e-13,3.89317349 3.89309214,0 8.6955061,0 C13.4979688,0 17.3913043,3.89322227 17.3913043,8.69557913 C17.3913043,10.7848609 16.6544881,12.7020297 15.4265331,14.2014302 Z M16.1490683,8.69557913 C16.1490683,4.57930647 12.8119112,1.24224646 8.6955061,1.24224646 C4.57916363,1.24224646 1.24223602,4.57924379 1.24223602,8.69557913 C1.24223602,12.8121336 4.57909059,16.1490579 8.6955061,16.1490579 C12.8119843,16.1490579 16.1490683,12.8120709 16.1490683,8.69557913 Z"
              ></path>
            </g>
          </g>
        </svg>
      </span>
    `;
  }
};
JbSearchIcon.styles = r(jb_search_icon_default);
JbSearchIcon = __decorateClass([
  customElement("jb-search-icon")
], JbSearchIcon);

export {
  JbSearchIcon
};
