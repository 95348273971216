import {
  e
} from "./chunk.5ICCMWV2.js";
import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  n
} from "./chunk.6SUS7CJL.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-service-navigation/jb-service-navigation.scss?inline
var jb_service_navigation_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

.service-navigation {
  align-self: center;
  display: var(--display);
  font-size: 0;
  opacity: 1;
  text-decoration: none;
  transition: opacity 0.35s ease-in-out 0.1s;
}
.service-navigation__bottom-links {
  margin-bottom: 90px;
  margin-top: 40px;
  text-align: center;
}
@media (orientation: landscape) {
  .service-navigation__bottom-links--second-level {
    margin-bottom: 70px;
  }
}
.service-navigation--hidden {
  opacity: 0;
}
.service-navigation--hide-all-except-first ::slotted(jb-service-navigation-item:nth-child(n+2)) {
  display: none;
}
.service-navigation--hide-first ::slotted(jb-service-navigation-item:first-child) {
  display: none;
}
.service-navigation--hide-all ::slotted(jb-service-navigation-item) {
  display: none;
}`;

// src/components/jb-service-navigation/jb-service-navigation.ts
var JbServiceNavigation = class extends s {
  render() {
    return x`
      <ul
        class=${e({
      "service-navigation": true,
      "service-navigation--hide-first": this.hideItems === "first",
      "service-navigation--hide-all-except-first": this.hideItems === "all-except-first",
      "service-navigation--hide-all": this.hideItems === "all"
    })}
      >
        <slot></slot>
      </ul>
    `;
  }
};
JbServiceNavigation.styles = r(jb_service_navigation_default);
__decorateClass([
  n()
], JbServiceNavigation.prototype, "hideItems", 2);
JbServiceNavigation = __decorateClass([
  customElement("jb-service-navigation")
], JbServiceNavigation);

export {
  JbServiceNavigation
};
