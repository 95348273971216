import {
  e as e2
} from "./chunk.5KBXDVSW.js";
import {
  e
} from "./chunk.UP75L23G.js";

// node_modules/lit-html/directives/unsafe-svg.js
var t = class extends e2 {
};
t.directiveName = "unsafeSVG", t.resultType = 2;
var o = e(t);

export {
  o
};
/*! Bundled license information:

lit-html/directives/unsafe-svg.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)
*/
