import {
  Spaceable
} from "./chunk.5AVD7Z5R.js";
import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  T,
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  n
} from "./chunk.6SUS7CJL.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-info-page-hero/jb-info-page-hero.scss?inline
var jb_info_page_hero_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p {
  font-family: var(--font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--font-light-weight, 300);
}

h1,
h2 {
  font-family: var(--font-xlight-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--font-xlight-weight, 200);
}

h1,
h2 {
  font-size: var(--typo-h1-overview-mobile-fontsize, 28px);
  letter-spacing: var(--typo-h1-overview-mobile-letterspacing, 1.5px);
  line-height: var(--typo-h1-overview-mobile-lineheight, 40px);
}
@media (min-width: 1281px) {
  h1,
  h2 {
    font-size: var(--typo-h1-overview-desktop-fontsize, 50px);
    letter-spacing: var(--typo-h1-overview-desktop-letterspacing, 4px);
    line-height: var(--typo-h1-overview-desktop-lineheight, 70px);
  }
}

p {
  font-size: var(--typo-p-big-mobile-fontsize, 19px);
  line-height: var(--typo-p-big-mobile-lineheight, 28px);
}
@media (min-width: 1281px) {
  p {
    font-size: var(--typo-p-big-desktop-fontsize, 22px);
    line-height: var(--typo-p-big-desktop-lineheight, 34px);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

div {
  padding: 0;
  text-align: center;
}

h1,
h2 {
  margin-block: 40px;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  h1,
  h2 {
    margin-bottom: 20px;
    margin-top: 0;
  }
}

p {
  margin: 32px auto 0;
}
@media (min-width: 1281px) {
  p {
    margin-bottom: 20px;
  }
}

::slotted(jb-breadcrumbs) {
  display: block;
  margin-bottom: 24px;
}
@media (min-width: 1281px) {
  ::slotted(jb-breadcrumbs) {
    margin-bottom: 50px;
  }
}`;

// src/components/jb-info-page-hero/jb-info-page-hero.ts
var JbInfoPageHero = class extends Spaceable(s) {
  constructor() {
    super(...arguments);
    this.headlineLevel = 1;
  }
  _getContentSpacingProperties() {
    return { colsMdUp: 10, type: "no-vertical-space" };
  }
  render() {
    return x`
      <div>
        <slot></slot>
        ${this.headlineLevel === 2 ? x`<h2>${this.header}</h2>` : x`<h1>${this.header}</h1>`}
        ${this.subheader ? x`<p>${this.subheader}</p>` : T}
      </div>
    `;
  }
};
JbInfoPageHero.styles = r(jb_info_page_hero_default);
__decorateClass([
  n()
], JbInfoPageHero.prototype, "header", 2);
__decorateClass([
  n({ type: Number })
], JbInfoPageHero.prototype, "headlineLevel", 2);
__decorateClass([
  n()
], JbInfoPageHero.prototype, "subheader", 2);
JbInfoPageHero = __decorateClass([
  customElement("jb-info-page-hero")
], JbInfoPageHero);

export {
  JbInfoPageHero
};
