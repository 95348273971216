// src/assets/scripts/CustomElementsUtils.ts
var customElement = (tagName) => (classOrTarget, context) => {
  if (context !== void 0) {
    context.addInitializer(() => {
      if (!customElements.get(tagName))
        customElements.define(tagName, classOrTarget);
    });
  } else {
    if (!customElements.get(tagName))
      customElements.define(tagName, classOrTarget);
  }
};

export {
  customElement
};
