import {
  o
} from "./chunk.MERL72F6.js";
import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  T,
  b,
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  n
} from "./chunk.6SUS7CJL.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-highlight-text-item/jb-highlight-text-item.scss?inline
var jb_highlight_text_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p, h3 {
  font-family: var(--font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--font-light-weight, 300);
}

h3 {
  font-size: var(--typo-h3-mobile-fontsize, 22px);
  line-height: var(--typo-h3-mobile-lineheight, 28px);
}
@media (min-width: 1281px) {
  h3 {
    font-size: var(--typo-h3-desktop-fontsize, 26px);
    line-height: var(--typo-h3-desktop-lineheight, 36px);
  }
}

p {
  font-size: var(--typo-p-small-desktop-fontsize, 16px);
  line-height: var(--typo-p-small-desktop-lineheight, 26px);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
}

svg {
  margin-bottom: 16px;
  max-width: 80px;
}

h3 {
  margin-bottom: 12px;
}`;

// src/components/jb-highlight-text-item/jb-highlight-text-item.ts
var JbHighlightTextItem = class extends s {
  render() {
    return x`
      ${this.svgIcon ? b`${o(this.svgIcon)}` : T}
      ${this.headline ? x`<h3>${this.headline}</h3>` : T}
      ${this.subline ? x`<p>${this.subline}</p>` : T}
    `;
  }
};
JbHighlightTextItem.styles = r(jb_highlight_text_item_default);
__decorateClass([
  n()
], JbHighlightTextItem.prototype, "headline", 2);
__decorateClass([
  n()
], JbHighlightTextItem.prototype, "subline", 2);
__decorateClass([
  n()
], JbHighlightTextItem.prototype, "svgIcon", 2);
JbHighlightTextItem = __decorateClass([
  customElement("jb-highlight-text-item")
], JbHighlightTextItem);

export {
  JbHighlightTextItem
};
