import {
  disableBodyScroll,
  enableBodyScroll
} from "./chunk.335TAW5S.js";
import {
  JbOverlay
} from "./chunk.EOGFK56K.js";
import {
  o
} from "./chunk.5KBXDVSW.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  Animatable
} from "./chunk.7WSJWXIT.js";
import {
  recenterNavItems
} from "./chunk.SPLM4LV4.js";
import {
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  n,
  r as r2
} from "./chunk.6SUS7CJL.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-news-overlay/jb-news-overlay.scss?inline
var jb_news_overlay_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  background-color: #f1f2f2;
  display: block;
  min-height: 100vh;
}

.news-overlay {
  opacity: 0;
}`;

// src/components/jb-news-overlay/jb-news-overlay.ts
var JbNewsOverlay = class extends Animatable(Initiable(s)) {
  constructor() {
    super(...arguments);
    this._responseHtml = "";
  }
  decodeHtml(htmlString) {
    const txt = document.createElement("textarea");
    txt.innerHTML = htmlString;
    return txt.value;
  }
  fetchDataFromApi(url) {
    fetch(url).then((response) => response.json()).then((data) => {
      this._responseHtml = data.html;
    });
  }
  firstUpdated() {
    this.fetchDataFromApi(this.url);
    this.dispatchEvent(
      new CustomEvent("jb-stackable:register-outro-callback", {
        bubbles: true,
        composed: true,
        detail: {
          callback: async () => {
            window.history.replaceState("", "", window.location.pathname + window.location.search);
          }
        }
      })
    );
  }
  render() {
    return x`<div class="news-overlay">${o(this.decodeHtml(this._responseHtml))}</div>`;
  }
};
JbNewsOverlay.styles = r(jb_news_overlay_default);
__decorateClass([
  n()
], JbNewsOverlay.prototype, "url", 2);
__decorateClass([
  r2()
], JbNewsOverlay.prototype, "_responseHtml", 2);
JbNewsOverlay = __decorateClass([
  customElement("jb-news-overlay")
], JbNewsOverlay);
bindEvents();

// src/components/jb-news-overlay/jb-news-overlay-controller.ts
var newsLinksRegister = {};
var JbNewsOverlayController = class {
  constructor() {
    this.overlay = void 0;
  }
  async close() {
    if (this.overlay) {
      const animatedElement = document.querySelector("jb-news-overlay");
      animatedElement.contentChildren = Array.from(animatedElement.shadowRoot.querySelectorAll("div.news-overlay"));
      await animatedElement.hide();
      this.overlay.unstack();
      enableBodyScroll(animatedElement);
      recenterNavItems();
    }
    this.overlay = void 0;
  }
  handleHashChange() {
    const hash = window.location.hash.substring(1);
    if (hash === "" || !hash.includes("overlayNews") || !hash.split("=")[1]) {
      this.close();
      return;
    }
    const translationId = Number(hash.split("=")[1]);
    if (!newsLinksRegister[translationId]) {
      this.close();
      return;
    }
    const url = newsLinksRegister[translationId];
    const jbNewsOverlay = new JbNewsOverlay().init({
      props: { url }
    });
    this.overlay = new JbOverlay().init({
      props: {
        color: "gray",
        hasLogo: true,
        hasCloseIcon: true,
        svgLogo: this.svgLogo,
        newAnimation: true
      },
      childNodes: [jbNewsOverlay]
    });
    if (this.overlay) {
      this.overlay.stack();
      customElements.whenDefined("jb-news-overlay").then(async () => {
        setTimeout(() => {
          jbNewsOverlay.contentChildren = Array.from(jbNewsOverlay.shadowRoot.querySelectorAll("div.news-overlay"));
        }, 0);
        disableBodyScroll(jbNewsOverlay, { allowTouchMove: () => true });
        recenterNavItems();
        await jbNewsOverlay.reveal();
      });
    }
  }
  open(event) {
    const customEvent = event;
    if (!customEvent.detail.newsOverlay) {
      return;
    }
    customEvent.stopPropagation();
    customEvent.detail.clickEvent.preventDefault();
    customEvent.detail.clickEvent.stopPropagation();
    const baseUrl = window.location.href.split("#")[0];
    const newUrl = baseUrl + "#overlayNews=" + customEvent.detail.newsOverlay.id;
    window.open(newUrl, "_self");
    this.svgLogo = customEvent.detail.svgLogo;
    this.handleHashChange();
  }
};
var controller = new JbNewsOverlayController();
function bindEvents() {
  document.body.addEventListener("jb-overlay:close", () => {
    controller.close();
  });
  document.body.addEventListener("jb-link:initialized", (event) => {
    const customEvent = event;
    if (!customEvent.detail.newsOverlay) {
      return;
    }
    newsLinksRegister[customEvent.detail.newsOverlay.id] = customEvent.detail.newsOverlay.url;
    const hash = window.location.hash.substring(1);
    if (hash === "" || !hash.includes("overlayNews") || !hash.split("=")[1]) {
      return;
    }
    const translationId = Number(hash.split("=")[1]);
    if (translationId === customEvent.detail.newsOverlay.id) {
      controller.handleHashChange();
    }
  });
  document.body.addEventListener("jb-link:clicked", (event) => {
    controller.open(event);
  });
}

export {
  bindEvents,
  JbNewsOverlay
};
