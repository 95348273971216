import {
  e,
  n
} from "./chunk.6SUS7CJL.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/mixins/ButtonElement.ts
var ButtonElementEvents = class {
  constructor() {
    this["jb-button:clicked"] = void 0;
  }
};
function ButtonElement(baseClass) {
  class ButtonElementMixin extends baseClass {
    async firstUpdated(_changedProperties) {
      super.firstUpdated(_changedProperties);
      this.addEventListener("click", (e2) => {
        this._click(e2);
      });
    }
    getTextContent() {
      const slot = this.shadowRoot?.querySelector("slot");
      if (!slot) {
        return null;
      }
      const nodes = slot.assignedNodes();
      if (nodes.length === 0) {
        return null;
      }
      const textNode = nodes[0];
      if (!textNode.textContent) {
        return null;
      }
      return textNode.textContent.trim();
    }
    triggerClick() {
      this._link?.click();
    }
    _click(clickEvent) {
      if (!this.name || this.disabled || this.inactive) {
        return;
      }
      clickEvent.stopPropagation();
      this.dispatchEvent(
        new CustomEvent("jb-button:clicked", {
          detail: { name: this.name, clickEvent },
          bubbles: true,
          composed: true
        })
      );
    }
  }
  __decorateClass([
    n({ type: Boolean })
  ], ButtonElementMixin.prototype, "disabled", 2);
  __decorateClass([
    n({ type: Boolean })
  ], ButtonElementMixin.prototype, "forceHover", 2);
  __decorateClass([
    n({ type: Boolean })
  ], ButtonElementMixin.prototype, "inactive", 2);
  __decorateClass([
    n()
  ], ButtonElementMixin.prototype, "name", 2);
  __decorateClass([
    e("a")
  ], ButtonElementMixin.prototype, "_link", 2);
  return ButtonElementMixin;
}

export {
  ButtonElementEvents,
  ButtonElement
};
